body {
    background: linear-gradient(to bottom right, lightsalmon, cornsilk) ;
    /*background-image: linear-gradient(to bottom right, white, darkkhaki);*/
    font-family: 'Patrick Hand';
}

ul {
    list-style: none;
    margin: 0;
    /*padding: 10px;*/
    display: inline-block;
    text-align: center;
}

li {
    /*margin: 15px;*/
}

h1 {
    padding: 15px;
    letter-spacing: 15px;
    color: coral;
    text-shadow: 2px 2px black;
    /*background: linear-gradient(to bottom right, lightsalmon, sandybrown) ;*/
}


.container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 40px;
}

.navLink {
    text-decoration: none;
    letter-spacing: 3px;
    font-size: 40px;
    color: olivedrab;
    text-shadow: 2px 2px black;
    font-weight: bold;
}

.navLink:hover {
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    color: olive;
}

.start-button {
    font-family: 'Patrick Hand';
    letter-spacing: 5px;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 40px;
    background-color: orange;
    border-radius: 8px;
    border: 1px solid chocolate;
    /*box-shadow: 5px 5px grey;*/
    transition: 0.3s;
    box-shadow: 1px 1px grey;
    margin: 25px 10px 0;
}

.start-button:hover {
    background-color: darkorange;
    /*border-color: darkorange;*/
    color: beige;
    box-shadow: none;
}

input {
    background: transparent;
    width: 60px;
    margin: 10px 10px;
    height: 30px;
    font-size: 30px;
    font-family: 'Patrick Hand';
    border: 0;
    border-bottom: 2px solid grey;
    font-weight: bold;
}

.guess-number-game-div {
    display: flex;
    align-items: center;
}

.guess-button {
    font-family: 'Patrick Hand';
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 40px;
    background-color: mediumseagreen;
    border-radius: 8px;
    border: 1px solid green;
    transition: 0.3s;
    box-shadow: 1px 1px grey;
    /*margin: 25px 10px 0;*/
}

.guess-button:hover:enabled {
    box-shadow: none;
    background-image: linear-gradient(to bottom right, mediumseagreen, seagreen);

}

.guess-button:disabled{
    background-color: darkseagreen;
    border:darkseagreen;
}

.down-button {
    font-family: 'Patrick Hand';
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 40px;
    background-color: gray;
    border-radius: 8px;
    border: 1px solid dimgray;
    transition: 0.3s;
    box-shadow: 1px 1px grey;
    margin: 10px 10px;
}

.down-button:hover {
    box-shadow: none;
    background-image: linear-gradient(to bottom right, gray, dimgray);
}

.up-button {
    font-family: 'Patrick Hand';
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 40px;
    background-color: gold;
    border-radius: 8px;
    border: 1px solid goldenrod;
    transition: 0.3s;
    box-shadow: 1px 1px grey;
    margin: 10px 10px;
}

.up-button:hover {
    box-shadow: none;
    background-image: linear-gradient(to bottom right, gold, goldenrod);
}


.no-button {
    font-family: 'Patrick Hand';
    letter-spacing: 2px;
    font-weight: bold;
    font-size: 16px;
    width: 100px;
    height: 40px;
    background-color: indianred;
    border-radius: 8px;
    border: 1px solid firebrick;
    transition: 0.3s;
    box-shadow: 1px 1px grey;
    margin: 10px 10px;
}

.no-button:hover {
    box-shadow: none;
    background-image: linear-gradient(to bottom right, indianred, firebrick);
}

